import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import './themes/styles/index.scss';
import './components/public/index.scss';
import './themes/styles/theme-color-variables.scss';
import './themes/styles/default-color-variables.scss';
import './utils/i18n';
import 'react-notifications/lib/notifications.css';
import * as serviceWorker from 'serviceWorker';
import { AppLoaderProvider } from 'contexts/AppLoaderContext';

const App = lazy(() => import('components/app/App'));

ReactDOM.render(
  <AppLoaderProvider>
    <App />
  </AppLoaderProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
